*{
    box-sizing: border-box;
    box-shadow: 20px;
   
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 20px auto 20px auto;
    width:400px;
    background: #252323;
    min-height: 400px;
    box-shadow: 0 8px 50px -7px black;
  }
  
  
  .show-calculation{
    display:flex;
    margin: 20px 25px;
    color:rgb(225, 18, 104);
    flex-direction: column;
  }
  
  .result-calculation{
    font-size:24px;
    flex:1;
    display: flex;
    justify-content: right;
  }
  
  .dashed-line{
    flex:1;
    margin-top:10px;
    border-bottom: 1px dashed grey;
  }
  
  .final-result{
    font-size:30px;
    flex:1;
    margin: 15px 25px 10px 0px;
    display: flex;
    color:white;
    justify-content: right;
  }
  
  .button-layout{
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  